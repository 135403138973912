body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #f8f8f8;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-display: swap;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

h4 {
  font-size: 18px;
}

.card-title {
  font-size: 16px;
}

table {
  color: #333;
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  /*height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  /*overflow-y: hidden;*/
  color: #333;
  padding-top:50px;
}

.text-primary {
  color: #337ab7;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.bg-default {
  background-color: #337ab7;
}

.ws-subscript { vertical-align:sub; }

.ws-superscript { vertical-align:super; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.navbar-nav {
  margin-right: 0;
}

/*.navbar-nav > .active > a { 
  background-color: #aaa; 
}*/
.navbar-nav li {
  display: inline-block;
}
.navbar-nav li:last-child {
  margin-right: 15px;
}
.navbar-nav li a {
  padding: 15px;
  min-height: 50px;
}
.navbar-nav .dropdown-menu li {
  display: block;
}
.navbar-nav .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-nav .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}
.navbar-nav .dropdown-menu li a div {
  white-space: normal;
}
.navbar-nav .dropdown-messages,
.navbar-nav .dropdown-tasks,
.navbar-nav .dropdown-alerts {
  width: 310px;
  min-width: 0;
}
.navbar-nav .dropdown-messages {
  margin-left: 5px;
}
.navbar-nav .dropdown-tasks {
  margin-left: -59px;
}
.navbar-nav .dropdown-alerts {
  margin-left: -123px;
}
.navbar-nav .dropdown-user {
  right: 0;
  left: auto;
}

.navbar-nav .dropdown-item {
  font-size: 14px;
  left: auto;
}

.nav .dropdown-item {
  font-size: 14px;
}

/*.nav-link>li>a:focus, .nav-link>li>a:hover {
  text-decoration: none;
  background-color: #eee;
  color: #777;
}*/

/*.nav-link>:hover {
  text-decoration: none;
  background-color: #eee;
  color: #777;
}*/

.nav-item:hover, .nav-item:focus {
  text-decoration: none;
  background-color: #eee;
  color: #777;
}

.nav-link {
  color: #777;
}

.b-example-divider {
  flex-shrink: 0;
  width: 0.5rem;
  height: 100vh;
  background-color: #f8f8f8;
  /*border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);*/
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle { outline: 0; }

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

.huge {
  font-size: 40px;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.card-primary > .card-header {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.card-success {
  border-color: #d6e9c6;
}

.card-success > .card-header {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.card-green {
  border-color: #5cb85c;
}
.card-green > .card-heading {
  border-color: #5cb85c;
  color: white;
  background-color: #5cb85c;
}
.card-green > a {
  color: #5cb85c;
}
.card-green > a:hover {
  color: #3d8b3d;
}
.card-red {
  border-color: #d9534f;
}
.card-red > .panel-heading {
  border-color: #d9534f;
  color: white;
  background-color: #d9534f;
}

.card-danger > .card-header {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-red > a {
  color: #d9534f;
}
.panel-red > a:hover {
  color: #b52b27;
}
.panel-yellow {
  border-color: #f0ad4e;
}
.panel-yellow > .panel-heading {
  border-color: #f0ad4e;
  color: white;
  background-color: #f0ad4e;
}
.panel-yellow > a {
  color: #f0ad4e;
}
.panel-yellow > a:hover {
  color: #df8a13;
}

/*@media print {

  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0px;
    padding: 0 0px;

  }

  .page-break-before {
      page-break-before: always;
  }

 .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: left;
 }
 .col-sm-12 {
      width: 100%;
 }
 .col-sm-11 {
      width: 91.66666667%;
 }
 .col-sm-10 {
      width: 83.33333333%;
 }
 .col-sm-9 {
      width: 75%;
 }
 .col-sm-8 {
      width: 66.66666667%;
 }
 .col-sm-7 {
      width: 58.33333333%;
 }
 .col-sm-6 {
      width: 50%;
 }
 .col-sm-5 {
      width: 41.66666667%;
 }
 .col-sm-4 {
      width: 33.33333333%;
 }
 .col-sm-3 {
      width: 25%;
 }
 .col-sm-2 {
      width: 16.66666667%;
 }
 .col-sm-1 {
      width: 8.33333333%;
 }
}

.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}*/
@media print {
  main {
    display: flex;
    flex-wrap: nowrap;
    /*height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    /*overflow-y: hidden;*/
    color: #333;
    padding-top:0px;
  }

  .hidden-print {
    display: none !important;
  }
}